<template>
  <div class="count-down">
    <span v-show="show" @click="getCode" class="mouse">获取验证码</span>
    <span v-show="!show" class="count">{{ count }} </span>
  </div>
</template>

<script>
export default {
  name: 'CountDown',
  data() {
    return {
      show: true,
      count: 60,
      timer: null
    }
  },
  methods: {
    getCode() {
      this.$emit('code')
    },
    countDown() {
      const TIME_COUNT = 60
      if (!this.timer) {
        this.count = TIME_COUNT
        this.show = false
        this.timer = setInterval(() => {
          if (this.count > 1 && this.count <= TIME_COUNT) {
            this.count--
          } else {
            this.show = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.count {
  // color: #0d86ff;
  cursor: default;
}
.mouse {
  cursor: pointer;
  color: #0d86ff;
}
</style>
